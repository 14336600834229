import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { KeycloakInstance } from "keycloak-js"
import { MenuItem, OutlinedInput, Select, TextField } from "@material-ui/core"
import customColors from "../../tailwind.customColors.json"
import { makeStyles } from "@material-ui/core/styles"
import { APISearchDevice } from "../APIRequests/APIGet"
import { useSnackbar } from "notistack"
import DeviceListTable from "./DeviceListTable"
import { IDeviceInfo } from "./Device"

export const productName: { [propName: string]: string } = {
  s19a01_107: "Hub",
  s19p01: "Sense",
  s18f01: "Press",
  s18m02: "Lite-Q",
  s21c01: "Lite-Q v2",
  s22e01: "Pro1",
  s22e02: "Pro1_AQ",
  s22z: "Companion",
}

interface Props {
  keycloak: KeycloakInstance
  setSelectedDeviceInfo: Dispatch<SetStateAction<IDeviceInfo | undefined>>
}

export default function SelectDevice({
  keycloak,
  setSelectedDeviceInfo,
}: Props) {
  const [selectedType, setSelectedType] = useState<string>("all")
  const [deviceFilter, setDeviceFilter] = useState<string>("")
  const [groupFilter, setGroupFilter] = useState<string>("")
  const [pageSize, setPageSize] = useState<string | number>(10)
  const [filterForm, setFilterForm] = useState<{ [p: string]: string }>({})
  const [timeOutId, setTimeOutId] = useState<any>(undefined)
  const [deviceList, setDeviceList] = useState<IDeviceInfo[] | undefined>(
    undefined
  )
  const [nextPageToken, setNextPageToken] = useState<string>("")

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    APISearchDevice({
      keycloak,
      search: filterForm.term ?? "",
      product: selectedType === "all" ? "" : selectedType,
      group: filterForm.group ?? "",
      pageSize: pageSize.toString(),
    }).then((response) => {
      if (response.status === 200) {
        setDeviceList(response.data.results)

        setNextPageToken(response.data.nextPageToken ?? "")
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" })
      }
    })
  }, [filterForm, selectedType, pageSize, keycloak, enqueueSnackbar])

  function handleFilterChange(event: ChangeEvent<any>, filterName: string) {
    switch (filterName) {
      case "device":
        setDeviceFilter(event.target.value)
        applyWithTimeOut({ term: event.target.value })
        break
      case "group":
        setGroupFilter(event.target.value)
        applyWithTimeOut({ group: event.target.value })
        break
      case "type":
        setSelectedType(event.target.value)
        break
      case "pageSize":
        setPageSize(event.target.value)
        break
    }
  }

  function handleLoadMore() {
    APISearchDevice({
      keycloak,
      search: filterForm.term ?? "",
      product: filterForm.product ?? "",
      nextPageToken,
    }).then((response) => {
      if (response.status === 200) {
        setDeviceList((prevState) => {
          let deviceListTemp = prevState === undefined ? [] : prevState.slice()
          deviceListTemp = [...deviceListTemp, ...response.data.results]

          return deviceListTemp
        })

        setNextPageToken(response.data.nextPageToken ?? "")
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" })
      }
    })
  }

  function applyWithTimeOut(newValue: { [p: string]: string }) {
    clearTimeout(timeOutId)

    const timeOutIdTemp = setTimeout(() => {
      setFilterForm({
        term: deviceFilter,
        group: groupFilter,
        ...newValue,
      })
    }, 500)

    setTimeOutId(timeOutIdTemp)
  }

  const useStylesSelect = makeStyles({
    root: {
      "&:focus": { outline: "none" },
      "& fieldset": { border: "none" },
    },
  })
  const classesSelect = useStylesSelect()

  const useStylesDivInput = makeStyles({
    root: {
      width: "11rem",
      "& .MuiFormHelperText-root.Mui-error": {
        color: customColors.danger,
        marginLeft: "2px",
        marginRight: "2px",
      },
    },
  })
  const classesDivInput = useStylesDivInput()

  const useStylesInput = makeStyles({
    root: {
      borderRadius: 0,
      border: `1px solid ${customColors.sidebar}`,
      backgroundColor: "white",
      "&:focus": { outline: "none" },
      "& fieldset": { border: "none" },
      "&$error": {
        border: `1px solid ${customColors.danger}`,
        color: "black",
      },
    },
    error: {},
  })
  const classesInput = useStylesInput()

  return (
    <div className="flex justify-center h-full">
      <div className="flex flex-col w-full p-10 items-start gap-4">
        {/* filter */}
        <div className="bg-popper p-5">
          <div className="flex justify-between">
            <div
              className="w-36 border border-sidebar bg-customGrey text-white text-center mb-3"
              style={{ height: "fit-content" }}
            >
              Filters
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <div>
              <div>Group:</div>
              <div>
                <TextField
                  type="text"
                  variant="outlined"
                  value={groupFilter}
                  classes={{ root: classesDivInput.root }}
                  InputProps={{
                    classes: {
                      root: classesInput.root,
                      error: classesInput.error,
                    },
                  }}
                  inputProps={{ style: { padding: "0.5rem" } }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleFilterChange(e, "group")}
                />
              </div>
            </div>
            <div>
              <div>Type:</div>
              <div>
                <Select
                  variant="outlined"
                  value={selectedType}
                  onChange={(e) => handleFilterChange(e, "type")}
                  style={{
                    borderRadius: 0,
                    border: `1px solid ${customColors.sidebar}`,
                    width: "11rem",
                  }}
                  SelectDisplayProps={{
                    style: {
                      borderRadius: 0,
                      padding: "0.5rem 2rem 0.5rem 0.5rem",
                      backgroundColor: "white",
                    },
                  }}
                  input={
                    <OutlinedInput classes={{ root: classesSelect.root }} />
                  }
                >
                  <MenuItem value="all">All</MenuItem>
                  {Object.entries(productName).map(([key, elem]) => (
                    <MenuItem key={key} value={key}>
                      {elem}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div>
              <div>Serial number or alias:</div>
              <div>
                <TextField
                  type="text"
                  variant="outlined"
                  value={deviceFilter}
                  classes={{ root: classesDivInput.root }}
                  InputProps={{
                    classes: {
                      root: classesInput.root,
                      error: classesInput.error,
                    },
                  }}
                  inputProps={{ style: { padding: "0.5rem" } }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleFilterChange(e, "device")}
                />
              </div>
            </div>
          </div>
        </div>
        {/* content */}
        <>
          {deviceList === undefined ? (
            <div>Loading...</div>
          ) : (
            <DeviceListTable
              deviceList={deviceList}
              setSelectedDeviceInfo={setSelectedDeviceInfo}
              productType={productName}
              nextPageToken={nextPageToken}
              handleLoadMore={handleLoadMore}
            />
          )}
        </>
      </div>
    </div>
  )
}
