import {
  Dispatch,
  RefObject,
  SetStateAction,
  useState,
  MouseEvent,
  Fragment,
} from "react"
import { IDeviceInfo } from "./DeviceLayoutContent/Device"
import { IDeviceConfig } from "./APIRequests/APIPut"
import AddToGroupSubMenu from "./DeviceLayoutContent/ConfigSubMenu/AddToGroupSubMenu"
import AliasSubMenu from "./DeviceLayoutContent/ConfigSubMenu/AliasSubMenu"
import { Popover } from "@material-ui/core"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import Keycloak from "keycloak-js"
import { makeStyles } from "@material-ui/core/styles"
import customColors from "../tailwind.customColors.json"
import { useHistory } from "react-router-dom"
import TagsSubMenu from "./DeviceLayoutContent/ConfigSubMenu/TagsSubMenu"
import CompanionsSubMenu from "./DeviceLayoutContent/ConfigSubMenu/CompanionSubMenu"
import MasterDeviceSubMenu from "./DeviceLayoutContent/ConfigSubMenu/MasterDeviceSubMenu"

interface IConfigSubMenuProps {
  keycloak: Keycloak.KeycloakInstance
  deviceInfo: IDeviceInfo
  setDeviceInfo: Dispatch<SetStateAction<IDeviceInfo | undefined>>
  deviceConfig: IDeviceConfig | undefined
  configContainerRef: RefObject<HTMLDivElement>
  saveConfig: (config: IDeviceConfig) => void
}

export default function ConfigSubMenu({
  keycloak,
  deviceInfo,
  setDeviceInfo,
  deviceConfig,
  configContainerRef,
  saveConfig,
}: IConfigSubMenuProps) {
  let history = useHistory()
  const subMenuDict: { [deviceType: string]: Array<string> } = {
    "": [],
    s19a01_107: ["groups", "alias", "tags"],
    s19p01: ["groups", "alias", "masterDevice"],
    s18f01: ["groups", "alias"],
    s18m02: ["groups", "alias"],
    s21c01: ["groups", "alias"],
    s22e01: ["groups", "alias", "companions"],
    s22e02: ["groups", "alias", "companions"],
    s22z11: ["groups", "alias", "masterDevice"],
  }

  const subMenuItem: {
    [propName: string]: { text: string; rollsAllowed?: string[] }
  } = {
    groups: {
      text: "Add to group",
    },
    alias: {
      text: "Alias",
    },
    tags: {
      text: "Tags",
    },
    companions: {
      text: "Companions",
    },
    masterDevice: {
      text: "Master device",
      rollsAllowed: [], // todo: delete when API implements `Master device`
    },
  }

  const [groupOpened, setGroupOpened] = useState<boolean>(false)
  const [aliasOpened, setAliasOpened] = useState<boolean>(false)
  const [tagsOpened, setTagsOpened] = useState<boolean>(false)
  const [companionsOpened, setCompanionsOpened] = useState<boolean>(false)
  const [masterDeviceOpened, setMasterDeviceOpened] = useState<boolean>(false)

  function toggleMenuDialog(
    e: MouseEvent<HTMLDivElement> | null,
    menuItemName: string,
    dialogState: boolean = true
  ) {
    if (menuItemName === "groups") setGroupOpened(dialogState)
    else if (menuItemName === "alias") setAliasOpened(dialogState)
    else if (menuItemName === "tags") setTagsOpened(dialogState)
    else if (menuItemName === "companions") setCompanionsOpened(dialogState)
    else if (menuItemName === "masterDevice") setMasterDeviceOpened(dialogState)
  }

  const useStyles = makeStyles({
    root: {},
    paper: {
      borderRadius: 0,
    },
  })
  const classes = useStyles()

  const menuArr = subMenuDict[deviceInfo.product] ?? subMenuDict[""]

  return (
    <div className="border-b-2 border-black h-16 flex">
      <div
        onClick={() => history.push("/config")}
        className="flex items-center px-5 h-full items-center hover:bg-popper cursor-pointer"
      >
        <NavigateBeforeIcon /> Back
      </div>
      {Object.entries(menuArr).map(([key, value]) => {
        const token = keycloak.tokenParsed
        const intersection =
          token?.resource_access?.configurator?.roles.filter((val) =>
            subMenuItem[value]["rollsAllowed"]?.includes(val)
          ) || []
        return (
          <Fragment key={key}>
            {subMenuItem[value]["rollsAllowed"] === undefined ||
            intersection.length > 0 ? (
              <div
                onClick={(e) => toggleMenuDialog(e, value, true)}
                className="flex items-center px-10 h-full items-center hover:bg-popper cursor-pointer"
              >
                {subMenuItem[value]["text"]}
              </div>
            ) : (
              <div
                style={{ color: customColors.popper }}
                className="flex items-center px-10 h-full items-center cursor-pointer"
              >
                {subMenuItem[value]["text"]}
              </div>
            )}
          </Fragment>
        )
      })}

      <Popover
        id="addToGroup"
        open={groupOpened}
        anchorEl={configContainerRef.current}
        onClose={() => toggleMenuDialog(null, "groups", false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ root: classes.root, paper: classes.paper }}
        transformOrigin={{ vertical: -10, horizontal: -10 }}
      >
        <AddToGroupSubMenu
          keycloak={keycloak}
          deviceInfo={deviceInfo}
          setDeviceInfo={setDeviceInfo}
          setGroupOpened={setGroupOpened}
        />
      </Popover>

      <Popover
        id="alias"
        open={aliasOpened}
        anchorEl={configContainerRef.current}
        onClose={() => toggleMenuDialog(null, "alias", false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ root: classes.root, paper: classes.paper }}
        transformOrigin={{ vertical: -10, horizontal: -10 }}
      >
        <AliasSubMenu
          keycloak={keycloak}
          deviceInfo={deviceInfo}
          setDeviceInfo={setDeviceInfo}
          setAliasOpened={setAliasOpened}
        />
      </Popover>

      <Popover
        id="tags"
        open={tagsOpened}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 150, left: 450 }}
        onClose={() => toggleMenuDialog(null, "tags", false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ root: classes.root, paper: classes.paper }}
        transformOrigin={{ vertical: -10, horizontal: -10 }}
      >
        <TagsSubMenu
          keycloak={keycloak}
          deviceConfig={deviceConfig}
          saveConfig={saveConfig}
          setTagsOpened={setTagsOpened}
          deviceInfo={deviceInfo}
        />
      </Popover>

      <Popover
        id="companions"
        open={companionsOpened}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 150, left: 450 }}
        onClose={() => toggleMenuDialog(null, "companions", false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ root: classes.root, paper: classes.paper }}
        transformOrigin={{ vertical: -10, horizontal: -10 }}
      >
        <CompanionsSubMenu
          keycloak={keycloak}
          deviceConfig={deviceConfig}
          saveConfig={saveConfig}
          setCompanionsOpened={setCompanionsOpened}
          deviceInfo={deviceInfo}
        />
      </Popover>

      <Popover
        id="masterDevice"
        open={masterDeviceOpened}
        anchorEl={configContainerRef.current}
        onClose={() => toggleMenuDialog(null, "masterDevice", false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ root: classes.root, paper: classes.paper }}
        transformOrigin={{ vertical: -10, horizontal: -10 }}
      >
        <MasterDeviceSubMenu
          keycloak={keycloak}
          deviceInfo={deviceInfo}
          setMasterDeviceOpened={setMasterDeviceOpened}
        />
      </Popover>
    </div>
  )
}
