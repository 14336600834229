import { createRef, Dispatch, SetStateAction, useEffect, useState } from "react"
import {
  APIGetConfigSchema,
  APIGetDeviceConfig,
  APIGetDeviceType,
  APIGetNotifications,
} from "../APIRequests/APIGet"
import PressDevice from "./s18f01/PressDevice"
import ConfigSubMenu from "../ConfigSubMenu"
import DeviceInfo from "./DeviceInfo"
import Keycloak from "keycloak-js"
import { APIPutDeviceConfig, IDeviceConfig } from "../APIRequests/APIPut"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import LiteQDevice from "./s18m02/LiteQDevice"
import LiteQDeviceV2 from "./s21c01/LiteQDeviceV2"
import Pro1 from "./s22e01/Pro1"
import Pro1Aq from "./s22e02/Pro1Aq"
import HubDevice from "./s19a01_107/HubDevice"
import SenseDevice from "./s19p01/SenseDevice"

export interface IConfigFormRouteProps {
  selectedDeviceInfo?: IDeviceInfo
  setSelectedDeviceInfo: Dispatch<SetStateAction<IDeviceInfo | undefined>>
  keycloak: Keycloak.KeycloakInstance
}

export interface IGroup {
  name: string
  id: string
}

export interface IDeviceInfo {
  alias: string
  fwVer: string
  groups: IGroup[]
  imei: string
  imsi: string
  lastSync: string
  pcb: string
  product: string
  productionDate: string
  sn: string
}

export interface INotification {
  email: {
    address: string
    enabled: boolean
  }
  name?: string
}

export interface notificationMessage {
  name: string
  body: string
}

export default function Device({
  selectedDeviceInfo,
  setSelectedDeviceInfo,
  keycloak,
}: IConfigFormRouteProps) {
  const [deviceConfig, setDeviceConfig] = useState<IDeviceConfig | undefined>()
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [deviceMessages, setDeviceMessages] = useState<notificationMessage[]>(
    []
  )
  const [configSchema, setConfigSchema] = useState<
    { [propName: string]: any } | undefined
  >()
  const [hasError, setHasError] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()

  // handle device ID from URL on page reload
  let { selectedDeviceId } = useParams<{ selectedDeviceId: string }>()
  if (selectedDeviceInfo === undefined) {
    APIGetDeviceType({ keycloak: keycloak, device_sn: selectedDeviceId }).then(
      (response) => {
        if (response.status === 200) {
          setSelectedDeviceInfo(response.data)
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
        }
      }
    )
  }

  useEffect(() => {
    if (
      selectedDeviceInfo !== undefined &&
      selectedDeviceInfo.product !== "s22z11"
    ) {
      APIGetConfigSchema({
        keycloak,
        productType: selectedDeviceInfo.product,
      }).then((response) => {
        if (response.status === 200) {
          setConfigSchema(response.data)
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
          setHasError(true)
        }
      })

      APIGetDeviceConfig({
        keycloak: keycloak,
        device_sn: selectedDeviceInfo.sn,
      }).then((response) => {
        if (response.status === 200) {
          setDeviceConfig(response.data.data)
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
          setHasError(true)
        }
      })

      APIGetNotifications({
        keycloak: keycloak,
        device_sn: selectedDeviceInfo.sn,
      }).then((response) => {
        if (response.status === 200) {
          setNotifications(response.data.contacts || [])
          setDeviceMessages(response.data.messages || [])
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
          setHasError(true)
        }
      })
    }
  }, [selectedDeviceInfo, keycloak, enqueueSnackbar])

  function saveConfig(config: IDeviceConfig) {
    if (selectedDeviceInfo !== undefined) {
      APIPutDeviceConfig({
        keycloak,
        device_sn: selectedDeviceInfo.sn,
        config,
      }).then((response) => {
        if (response.status === 200) {
          setDeviceConfig(response.data.data)
          enqueueSnackbar("Config successfully updated", { variant: "default" })
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" })
        }
      })
    }
  }

  const configContainerRef = createRef<HTMLDivElement>()

  return (
    <div className="flex justify-center h-full">
      <div className="flex w-full">
        <div className="flex-grow">
          {selectedDeviceInfo !== undefined && (
            <ConfigSubMenu
              deviceInfo={selectedDeviceInfo}
              setDeviceInfo={setSelectedDeviceInfo}
              deviceConfig={deviceConfig}
              keycloak={keycloak}
              configContainerRef={configContainerRef}
              saveConfig={saveConfig}
            />
          )}
          <div className="p-10" ref={configContainerRef}>
            {selectedDeviceInfo === undefined ||
            configSchema === undefined ||
            deviceConfig === undefined ? (
              <div>{hasError ? "Something goes wrong..." : "Loading..."}</div>
            ) : (
              <>
                {selectedDeviceInfo.product === "s19a01_107" && (
                  <HubDevice
                    deviceConfig={deviceConfig}
                    saveConfig={saveConfig}
                    configSchema={configSchema}
                  />
                )}
                {selectedDeviceInfo.product === "s19p01" && (
                  <SenseDevice
                    deviceConfig={deviceConfig}
                    configSchema={configSchema}
                    saveConfig={saveConfig}
                  />
                )}
                {selectedDeviceInfo.product === "s18f01" && (
                  <PressDevice
                    selectedDeviceInfo={selectedDeviceInfo}
                    keycloak={keycloak}
                    deviceConfig={deviceConfig}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    saveConfig={saveConfig}
                    deviceMessages={deviceMessages}
                    setDeviceMessages={setDeviceMessages}
                    configSchema={configSchema}
                  />
                )}
                {selectedDeviceInfo.product === "s18m02" && (
                  <LiteQDevice
                    deviceConfig={deviceConfig}
                    saveConfig={saveConfig}
                    configSchema={configSchema}
                  />
                )}
                {selectedDeviceInfo.product === "s21c01" && (
                  <LiteQDeviceV2
                    deviceConfig={deviceConfig}
                    saveConfig={saveConfig}
                    configSchema={configSchema}
                  />
                )}
                {selectedDeviceInfo.product === "s22e01" && (
                  <Pro1
                    deviceConfig={deviceConfig}
                    saveConfig={saveConfig}
                    configSchema={configSchema}
                  />
                )}
                {selectedDeviceInfo.product === "s22e02" && (
                  <Pro1Aq
                    deviceConfig={deviceConfig}
                    saveConfig={saveConfig}
                    configSchema={configSchema}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {selectedDeviceInfo !== undefined && (
          <div className="flex-none flex flex-col align-middle gap-4 w-80 p-10 border-l-2 border-black">
            <DeviceInfo device={deviceConfig} deviceInfo={selectedDeviceInfo} />
          </div>
        )}
      </div>
    </div>
  )
}
